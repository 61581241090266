import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./CarrouselHero.css";
import Slides from "./Slides";

function CarrouselHero() {
  const [activeSlide, setActiveSlide] = useState(0);
  const slidesData = Slides();

  // Efeito para mudar slides automaticamente
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prev) => (prev + 1) % slidesData.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [slidesData.length]);

  return (
    <div className="carrousel-container">
      <AnimatePresence>
        {slidesData.map(
          (slide, index) =>
            index === activeSlide && (
              <motion.div
                key={index}
                className="slide"
                initial={{ x: "100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: "-100%", opacity: 0 }}
                transition={{ duration: 1 }}
              >
                {slide}
              </motion.div>
            )
        )}
      </AnimatePresence>
      <div className="indicators">
        {slidesData.map((_, index) => (
          <div
            key={index}
            className={`indicator ${index === activeSlide ? "active" : ""}`}
            onClick={() => setActiveSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default CarrouselHero;
