import LogoSvg from "../ui_components/LogoSvg";
import "./Footer.css";

function Footer() {
  return (
    <section className="footer">
      <div className="footer__container">
        <LogoSvg />
      </div>
    </section>
  );
}

export default Footer;
