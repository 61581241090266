// HomePage.js
import React from "react";
import LogoSgv from "../ui_components/LogoSvg";
import OurTeam from "../main_components/OurTeam";
import FontSection from "../main_components/FontsSection";
import CarrouselHero from "../ui_components/CarrouselHero";
import ManifestExperience from "../main_components/ManifestExperience";
import ManifestTool from "../main_components/ManifestTool";
import ContactSection from "../main_components/ContactSection";

function Home() {
  return (
    <>
      <section className="opening">
        <div className="horizontal-line fixed-item" id="line1"></div>
        <div className="hero">
          <CarrouselHero />
        </div>
        <div className="horizontal-line fixed-item" id="line2"></div>
      </section>
      <LogoSgv />
      <OurTeam />
      <ManifestExperience />
      <FontSection />
      <ManifestTool />
      <ContactSection />
    </>
  );
}

export default Home;
