import "./ManifestExperience.css";

function ManifestExperience() {
  return (
    <section
      className="manifest-technical manifest-section"
      style={{
        backgroundImage: "url(/assets/img/logo_background-square.webp)",
        // ,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "100%",
        // backgroundPosition: "center",
        // backgroundAttachment: "fixed",
      }}
    >
      <div className="manifest-container">
        <div className="manifest-sentence">
          <p className="manifest-p">
            Nossa experiência nos dá compreensão única das necessidades dos
            clientes.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ManifestExperience;
