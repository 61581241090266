import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./ContactSection.css";
import "./Forms.css";

const ContactSection = () => {
  const [status, setStatus] = useState("idle");

  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    mensagem: "",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ligatipo-form",
        "template_cyeycir",
        e.target,
        "otInTKq0q4NZ5pmo4"
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatus("success");
          // Aqui você pode colocar alguma notificação de sucesso
        },
        (error) => {
          console.log(error.text);
          setStatus("error");
          // Aqui você pode colocar alguma notificação de erro
        }
      );
  };

  return (
    <section className="contact-section" id="contact">
      <div className="title-container">
        <h2 class="section__title">contato</h2>
      </div>
      <div className="contact-section__container">
        <p>
          Vamos conversar? Mande uma mensagem pra gente aqui ou no Instagram.
        </p>
        {status === "idle" && (
          <form className="colab-form" onSubmit={handleSubmit}>
            <div className="label-float">
              <input
                type="text"
                name="nome"
                onChange={handleChange}
                value={formData.nome}
                placeholder=""
                required
                autofill="off"
              />
              <label htmlFor="nome">nome</label>
            </div>

            <div className="label-float">
              <input
                type="email"
                name="email"
                onChange={handleChange}
                value={formData.email}
                placeholder=" "
                required
              />
              <label htmlFor="email">email</label>
            </div>

            <div className="label-float area">
              <textarea
                name="mensagem"
                onChange={handleChange}
                value={formData.mensagem}
                placeholder=" "
                required
              />
              <label htmlFor="mensagem">mensagem</label>
            </div>

            <button className="btn" type="submit">
              enviar
            </button>
          </form>
        )}
        {status === "success" && (
          <div className="success-message">
            <h2>Email enviado com sucesso!</h2>
            <p>Entraremos em contato em breve.</p>
          </div>
        )}
        {status === "error" && (
          <div className="error-message">
            <h2>Ops, algo deu errado!</h2>
            <button className="btn" onClick={() => setStatus("idle")}>
              Tentar Novamente
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default ContactSection;
