import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

function Menu({ isOpen }) {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <motion.nav
      className={`menu ${isOpen ? "open" : ""}`}
      initial={isMobile ? { y: "-100%" } : {}}
      animate={isMobile ? { y: isOpen ? 0 : "-100%" } : {}}
      transition={isMobile ? { duration: 0.4 } : {}}
    >
      {!isHome && (
        <a className="menu_item" href="/">
          home
        </a>
      )}
      {isHome && (
        <>
          <a className="menu_item" href="/#our-team">
            nossa equipe
          </a>
          <a className="menu_item" href="/#projects">
            projetos
          </a>
          <a className="menu_item" href="/#contact">
            contato
          </a>
        </>
      )}
      <a className="menu_item" href="/colab">
        produza com a gente
      </a>
    </motion.nav>
  );
}

export default Menu;
