import React from "react";
import FontCard from "../ui_components/FontCard";
import "./FontSection.css";
import cardsData from "../data/cardsData.json";

function FontSection() {
  return (
    <section className="font-section section" id="projects">
      <h2 className="section__title">projetos</h2>
      <div className="cards-container">
        {cardsData.map((card) => (
          <FontCard
            fontObj={card}
            key={card.familyName}
            familyName={card.familyName}
            productionStatus={card.productionStatus}
            buyButtonText={card.buyButtonText}
            cardImage={card.cardImage}
            familyStyles={card.familyStyles}
            familyVersion={card.familyVersion}
            fontPrice={card.fontPrice}
          />
        ))}
      </div>
    </section>
  );
}

// function FontSection() {
//   const sectionRef = useRef(null);

//   return (
//     <section className="font-section section" id="projects" ref={sectionRef}>
//       <h2 className="section__title">projetos</h2>
//       <div className="cards-container">
//         {cardsData.map((card) => (
//           <FontCard
//             fontObj={card}
//             key={card.familyName}
//             familyName={card.familyName}
//             productionStatus={card.productionStatus}
//             buyButtonText={card.buyButtonText}
//             cardImage={card.cardImage}
//             familyStyles={card.familyStyles}
//             familyVersion={card.familyVersion}
//             fontPrice={card.fontPrice}
//           />
//         ))}
//       </div>
//     </section>
//   );
// }

export default FontSection;
