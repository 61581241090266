import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import "./FontCard.css";

// function priceCalculator(fontPrice, familyVersion, familyStyles) {
//   let discount;
//   // apply discount based on version
//   if (familyVersion <= 0.0) {
//     discount = 0.5; // 50% discount
//   } else if (familyVersion <= 0.33) {
//     discount = 0.5 * (2 / 3);
//   } else if (familyVersion <= 0.66) {
//     discount = 0.5 * (1 / 3); // 16.67% discount
//   } else {
//     discount = 0.0; // no discount
//   }

//   // return final price
//   const finalPrice = fontPrice * familyStyles * (1 - discount);
//   return parseInt(finalPrice);
// }

function FontCard(fontObj) {
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const mouseXSpring = useSpring(x);
  const mouseYSpring = useSpring(y);

  const rotateX = useTransform(mouseYSpring, [-0.5, 0.5], ["15deg", "-15deg"]);
  const rotateY = useTransform(mouseXSpring, [-0.5, 0.5], ["-15deg", "15deg"]);

  const handleMouseMove = (e) => {
    const cardRect = e.target.getBoundingClientRect();

    const cardWidth = cardRect.width;
    const cardHeight = cardRect.height;

    const mouseX = e.clientX - cardRect.left;
    const mouseY = e.clientY - cardRect.top;

    const xPerc = mouseX / cardWidth - 0.5;
    const yPerc = mouseY / cardHeight - 0.5;

    x.set(xPerc);
    y.set(yPerc);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };

  return (
    <motion.div
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      className="font-card"
      style={{ rotateX, rotateY }}
    >
      <div className="font-card__image">
        <img src={fontObj.cardImage} alt={fontObj.familyName} />
      </div>
      <div className="font-card__info-container">
        <div className="font-card__txt-container">
          <div className="font-card__name">{fontObj.familyName}</div>
        </div>
      </div>

      {/* NEXT PHASE */}
      {/* <div className="font-card__info-container">
        <div className="font-card__txt-container">
          <div className="font-card__name">{fontObj.familyName}</div>
          <div className="font-card__status">
            <span>licença </span>
            {fontObj.productionStatus}
          </div>
          <div className="font-card__styles">
            <span>estilos </span>
            {fontObj.familyStyles}
          </div>
          <div className="font-card__version">
            <span>versão </span>
            {fontObj.familyVersion}
          </div>
          <div className="font-card__price">
            <span>R${fontObj.fontPrice * fontObj.familyStyles} </span>
            <span>
              R$
              {priceCalculator(
                fontObj.fontPrice,
                fontObj.familyVersion,
                fontObj.familyStyles
              )}
            </span>
          </div>
        </div> */}
      {/* <div className="font-card__button-container">
          <div className="font-card__plus-button">{plus_button}</div>
          <button className="font-card__buy-button">
            {fontObj.buyButtonText}
          </button>
        </div> */}
      {/* </div> */}
    </motion.div>
  );
}

export default FontCard;
