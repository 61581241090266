import React from "react";
import "./OurTeam.css";

function OurTeam() {
  return (
    <section id="our-team" className="our-team section">
      <h2 className="section__title">nossa equipe</h2>
      <div className="team-member-container">
        <div className="team-member deia">
          <div className="team-member__image">
            <img
              className="raquete raquete--esquerda"
              src="/assets/img/raquete-esquerda.svg"
              alt="Raquete"
            />
            <img src="/assets/img/deia.webp" alt="Deia Kulpas" />
          </div>
          <div className="team-member__name">Deia Kulpas</div>
          <div className="team-member__bio">
            <p className="team-member__bio__text">
              Andrea Kulpas é graduada em Design Gráfico pela UNESP e possui
              especialização em Design de Tipografia pela Universidade de Buenos
              Aires. Com mais de 20 anos de experiência na área, ela trabalha em
              projetos de branding, identidade visual e embalagem. Andrea já
              colaborou com agências renomadas, como FutureBrand, Questtonó e
              Landor & Fitch, e atualmente desempenha o papel de Coordenadora de
              Projetos na Tátil. Além de sua carreira profissional, Andrea é uma
              ativista da causa da representatividade feminina na tipografia e
              já fez palestras sobre o tema em diversos eventos. Ela acredita na
              importância de valorizar a diversidade cultural e de gênero.
              Andrea também é professora na Miami Ad School e na EBAC,
              compartilhando seu conhecimento e experiência com as novas
              gerações de designers.
            </p>
          </div>
        </div>
        <div className="team-member filipe">
          <div className="team-member__image">
            <img
              className="raquete raquete--direita"
              src="/assets/img/raquete-direita.svg"
              alt="Raquete"
            />
            <img src="/assets/img/filipe.webp" alt="Filipe Negrão" />
          </div>
          <div className="team-member__name">Filipe Negrão</div>
          <div className="team-member__bio">
            <p className="team-member__bio__text">
              Filipe Negrão é um designer gráfico com formação pelo Centro
              Universitário Senac São Paulo. Possui diversas especializações,
              incluindo Typeface Design pela Cooper Union New York, MBA em
              Gestão de Negócios Internacionais pela Facamp e User Experience
              (UX) e Design de Experiências pela Udesc. Além disso, teve a
              oportunidade de aprimorar suas habilidades como type designer em
              programas internacionais como Practica, DesignDesign.Space e ILT
              Academy. Filipe também cursou o mestrado em Artes Visuais na
              Unicamp. Desde 2005, tem se dedicado ao desenho de marcas e
              iniciou sua carreira no renomado estúdio Lab Mattar. Ao longo dos
              últimos 16 anos, atuou como professor (atualmente licenciado) nos
              cursos de Design, Propaganda e Marketing da Facamp e na
              Especialização em Design Gráfico da Unicamp. Hoje, é membro do
              conselho nacional do DiaTipo, o principal evento de Tipografia da
              América Latina, e representa a ATypI (Association Typographique
              Internationale) no Brasil.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

// function OurTeam() {
//   const sectionRef = useRef(null);

//   return (
//     <section id="our-team" className="our-team section" ref={sectionRef}>
//       <h2 className="section__title">nossa equipe</h2>
//       <div className="team-member-container">
//         <div className="team-member deia">
//           <div className="team-member__image">
//             <img
//               className="raquete raquete--esquerda"
//               src="/assets/img/raquete-esquerda.svg"
//               alt="Raquete"
//             />
//             <img src="/assets/img/deia.webp" alt="Deia Kulpas" />
//           </div>
//           <div className="team-member__name">Deia Kulpas</div>
//           <div className="team-member__bio">
//             <p className="team-member__bio__text">
//               Andrea Kulpas é graduada em Design Gráfico pela UNESP e possui
//               especialização em Design de Tipografia pela Universidade de Buenos
//               Aires. Com mais de 20 anos de experiência na área, ela trabalha em
//               projetos de branding, identidade visual e embalagem. Andrea já
//               colaborou com agências renomadas, como FutureBrand, Questtonó e
//               Landor & Fitch, e atualmente desempenha o papel de Coordenadora de
//               Projetos na Tátil. Além de sua carreira profissional, Andrea é uma
//               ativista da causa da representatividade feminina na tipografia e
//               já fez palestras sobre o tema em diversos eventos. Ela acredita na
//               importância de valorizar a diversidade cultural e de gênero.
//               Andrea também é professora na Miami Ad School e na EBAC,
//               compartilhando seu conhecimento e experiência com as novas
//               gerações de designers.
//             </p>
//           </div>
//         </div>
//         <div className="team-member filipe">
//           <div className="team-member__image">
//             <img
//               className="raquete raquete--direita"
//               src="/assets/img/raquete-direita.svg"
//               alt="Raquete"
//             />
//             <img src="/assets/img/filipe.webp" alt="Filipe Negrão" />
//           </div>
//           <div className="team-member__name">Filipe Negrão</div>
//           <div className="team-member__bio">
//             <p className="team-member__bio__text">
//               Filipe Negrão é um designer gráfico com formação pelo Centro
//               Universitário Senac São Paulo. Possui diversas especializações,
//               incluindo Typeface Design pela Cooper Union New York, MBA em
//               Gestão de Negócios Internacionais pela Facamp e User Experience
//               (UX) e Design de Experiências pela Udesc. Além disso, teve a
//               oportunidade de aprimorar suas habilidades como type designer em
//               programas internacionais como Practica, DesignDesign.Space e ILT
//               Academy. Filipe também cursou o mestrado em Artes Visuais na
//               Unicamp. Desde 2005, tem se dedicado ao desenho de marcas e
//               iniciou sua carreira no renomado estúdio Lab Mattar. Ao longo dos
//               últimos 16 anos, atuou como professor (atualmente licenciado) nos
//               cursos de Design, Propaganda e Marketing da Facamp e na
//               Especialização em Design Gráfico da Unicamp. Hoje, é membro do
//               conselho nacional do DiaTipo, o principal evento de Tipografia da
//               América Latina, e representa a ATypI (Association Typographique
//               Internationale) no Brasil.
//             </p>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

export default OurTeam;
