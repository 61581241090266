import React from "react";

function Slides() {
  return [
    <img src="/assets/hero-slides/ar-sans.webp" alt="Ar Sans" />,
    <img src="/assets/hero-slides/cerol.webp" alt="Cerol" />,
    <img src="/assets/hero-slides/olar.webp" alt="Olar" />,
    <img src="/assets/hero-slides/leluja.webp" alt="Leluja" />,
  ];
}

export default Slides;
