import SvgGiveBack from "../ui_components/SvgGiveBack";
import "./ManifestGiveback.css";

function Giveback() {
  return (
    <section className="giveback">
      <SvgGiveBack className="giveback-image" />
    </section>
  );
}

export default Giveback;
