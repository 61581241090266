import * as React from "react";

const LogoSvg = (props) => (
  <svg
    id="svg-logo"
    width={150}
    height={150}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="logo">
      <g id="logo_group">
        <g id="background_circle">
          <path
            id="circle"
            className="logo_background_color"
            d="M75 148.758C115.736 148.758 148.758 115.736 148.758 75C148.758 34.2645 115.736 1.24178 75 1.24178C34.2644 1.24178 1.24176 34.2645 1.24176 75C1.24176 115.736 34.2644 148.758 75 148.758Z"
            fill="#FFDD44"
          />
          <path
            id="line"
            className="logo_foreground_color"
            d="M75 149.984C33.6394 149.984 0 116.345 0 75C0 33.6553 33.6394 0 75 0C116.361 0 150 33.6394 150 75C150 116.361 116.361 150 75 150V149.984ZM75 2.48355C35.0085 2.48355 2.48355 35.0085 2.48355 75C2.48355 114.992 35.0085 147.501 74.9841 147.501C114.96 147.501 147.485 114.976 147.485 75C147.485 35.0244 114.976 2.48355 75 2.48355Z"
            fill="#015958"
          />
        </g>
        <g />
        <g id="smile_face">
          <path
            id="Vector"
            className="logo_foreground_color"
            d="M76.4646 54.5266C65.2727 54.2719 56.0072 62.9803 55.7684 73.8219C55.5296 84.6636 64.3812 93.8177 75.5731 94.0565C86.765 94.3112 96.1102 85.6188 96.349 74.7294C96.5878 63.8877 87.6406 54.7814 76.4487 54.5266H76.4646ZM88.3411 86.8287C81.7661 92.958 70.8926 92.2734 64.1106 85.38C57.3604 78.5184 57.1534 67.9155 63.7285 61.8022C70.3035 55.6729 81.2248 56.3097 87.9749 63.1713C94.7569 70.0647 94.9161 80.6994 88.3252 86.8287H88.3411Z"
            fill="#015958"
          />
          <path
            id="Vector_2"
            className="logo_foreground_color"
            d="M80.2218 81.3203L82.18 81.3681C82.1004 84.8864 79.4099 86.6217 76.7671 86.5581C74.1244 86.4944 71.5135 84.6636 71.5931 81.1293L73.5513 81.177C73.5035 83.5173 75.1433 84.6954 76.8149 84.7432C78.4865 84.7909 80.1741 83.6765 80.2377 81.3362L80.2218 81.3203Z"
            fill="#015958"
          />
          <path
            id="Vector_3"
            className="logo_foreground_color"
            d="M85.969 65.83L82.6258 65.7504L80.3969 65.7026L76.6875 65.623L76.6557 67.3583L80.3492 67.4379L80.1104 78.3751L82.3551 78.4228L82.5939 67.4857L85.9372 67.5653L85.969 65.83Z"
            fill="#015958"
          />
          <path
            id="Vector_4"
            className="logo_foreground_color"
            d="M69.6508 76.3851L69.8896 65.4956L67.6449 65.4479L67.3742 78.0885L68.6797 78.1204L69.603 78.1363L73.6308 78.2159L73.6786 76.4806L69.6508 76.3851Z"
            fill="#015958"
          />
        </g>
        <g id="ligatipo">
          <path
            id="Vector_5"
            className="logo_foreground_color"
            d="M36.5527 61.9932L37.7308 59.1913L46.5188 62.9166L48.2063 58.9206L50.5466 59.9077L47.6809 66.6897L36.5527 61.9773V61.9932Z"
            fill="#015958"
          />
          <path
            id="Vector_6"
            className="logo_foreground_color"
            d="M42.6979 50.5466L44.6084 48.1904L53.9694 55.8162L52.059 58.1724L42.6979 50.5466Z"
            fill="#015958"
          />
          <path
            id="Vector_7"
            className="logo_foreground_color"
            d="M51.3744 49.2252C49.1615 45.9297 50.1804 42.3636 53.1893 40.3418C53.9694 39.8164 54.8769 39.3865 56.055 39.0363L57.7266 40.8671C56.1823 41.3766 55.3226 41.8064 54.7973 42.1726C53.0301 43.3507 52.3296 45.1019 53.8898 47.4103C55.4022 49.6551 57.3764 50.0053 59.3345 48.6839C59.446 48.6043 59.5574 48.5247 59.7007 48.4133L57.5037 45.1337L60.0191 43.4462L63.235 48.2382C61.8658 49.7824 61.0062 50.467 60.3375 50.9287C56.6599 53.3963 53.253 52.059 51.3585 49.2252H51.3744Z"
            fill="#015958"
          />
          <path
            id="Vector_8"
            className="logo_foreground_color"
            d="M67.1832 35.1518L70.5901 34.6264L76.7512 45.9138L73.599 46.4073L72.5164 44.29L68.2817 44.9427L67.8996 47.2989L64.7474 47.7924L67.1832 35.1836V35.1518ZM71.2906 41.886L69.3961 38.1766L68.7274 42.284L71.2906 41.886Z"
            fill="#015958"
          />
          <path
            id="Vector_9"
            className="logo_foreground_color"
            d="M81.7183 37.508L78.3591 36.7756L78.9004 34.2921L88.5958 36.3935L88.0545 38.8771L84.6954 38.1448L82.6576 47.474L79.6805 46.8213L81.7183 37.492V37.508Z"
            fill="#015958"
          />
          <path
            id="Vector_10"
            className="logo_foreground_color"
            d="M92.6714 38.3677L95.3619 39.7845L89.7262 50.4829L87.0356 49.066L92.6714 38.3677Z"
            fill="#015958"
          />
          <path
            id="Vector_11"
            className="logo_foreground_color"
            d="M103.354 45.4999C105.853 47.9357 105.885 50.3237 104.07 52.1864C102.319 54.0013 99.9469 54.1605 97.5271 51.8202L96.938 51.263L94.3112 53.9694L92.1301 51.852L100.568 43.1596C101.539 43.9397 102.669 44.8631 103.338 45.5158L103.354 45.4999ZM99.2146 49.9257C100.122 50.8013 101.077 50.8332 101.857 50.0371C102.606 49.273 102.685 48.3974 101.666 47.4103C101.539 47.283 101.364 47.1397 101.141 46.9486L98.7052 49.4481L99.1987 49.9257H99.2146Z"
            fill="#015958"
          />
          <path
            id="Vector_12"
            className="logo_foreground_color"
            d="M103.991 55.1157C107.382 53.5555 110.709 54.6699 112.19 57.854C113.654 61.0221 112.349 64.2857 108.958 65.8459C105.535 67.422 102.176 66.3235 100.711 63.1554C99.2465 59.9713 100.584 56.6918 103.991 55.1157ZM102.908 62.1365C103.72 63.9036 105.646 63.9514 107.652 63.0121C109.658 62.0887 110.805 60.6241 109.993 58.8569C109.165 57.0898 107.318 56.9943 105.296 57.9336C103.306 58.8569 102.08 60.3693 102.908 62.1365Z"
            fill="#015958"
          />
        </g>
        <g id="foundry">
          <path
            id="Vector_13"
            className="logo_foreground_color"
            d="M47.6651 89.1053L50.1645 93.1968L48.7476 94.0565L47.283 91.6684L45.8661 92.5281L47.2034 94.7251L45.7865 95.5848L44.4333 93.3878L41.9179 94.9002L40.8831 93.1968L47.6491 89.1053H47.6651Z"
            fill="#015958"
          />
          <path
            id="Vector_14"
            className="logo_foreground_color"
            d="M49.6233 98.8166C51.2153 96.9699 53.5078 96.7311 55.259 98.2116C56.9943 99.6922 57.1057 101.985 55.5137 103.831C53.9058 105.694 51.5974 105.965 49.8621 104.484C48.1108 103.004 48.0153 100.679 49.6233 98.8166ZM50.8969 103.29C51.868 104.118 53.0302 103.625 53.9695 102.526C54.9088 101.428 55.1953 100.249 54.2242 99.4216C53.2531 98.5937 52.1227 99.0395 51.1675 100.154C50.2282 101.236 49.9098 102.478 50.881 103.306L50.8969 103.29Z"
            fill="#015958"
          />
          <path
            id="Vector_15"
            className="logo_foreground_color"
            d="M59.4141 106.952L61.0857 102.542L62.9006 103.226L61.1653 107.812C60.831 108.671 61.0857 109.435 61.9454 109.754C62.8051 110.072 63.5375 109.69 63.8718 108.83L65.6071 104.245L67.422 104.93L65.7504 109.324C64.9384 111.441 63.426 112.062 61.3723 111.282C59.3823 110.534 58.5863 109.133 59.4141 106.936V106.952Z"
            fill="#015958"
          />
          <path
            id="Vector_16"
            className="logo_foreground_color"
            d="M71.2906 105.694H73.3284L76.0826 110.598L76.1463 105.726H78.0726L77.9612 113.638H75.9234L73.1692 108.767L73.1055 113.606H71.1792L71.2906 105.694Z"
            fill="#015958"
          />
          <path
            id="Vector_17"
            className="logo_foreground_color"
            d="M84.4088 104.15C87.3063 103.131 89.4874 103.927 90.3311 106.315C91.1908 108.751 89.9172 110.773 87.5451 111.616L84.9023 112.54L82.2755 105.105C83.0397 104.707 83.7879 104.373 84.4088 104.15ZM87.2744 110.056C88.2297 109.722 88.9779 108.623 88.4207 107.047C87.9113 105.615 86.7491 105.01 85.3163 105.519C85.1889 105.567 84.966 105.646 84.6317 105.821L86.2556 110.422L87.2744 110.072V110.056Z"
            fill="#015958"
          />
          <path
            id="Vector_18"
            className="logo_foreground_color"
            d="M93.8495 99.1987C95.7758 97.6704 97.336 97.8614 98.2912 99.0554C98.8962 99.8196 99.1191 100.775 98.737 101.698L99.7081 102.112C100.504 102.462 100.966 102.446 101.428 102.287L101.475 103.704C100.488 104.261 99.6763 104.054 99.0076 103.736L97.6703 103.099L97.6067 103.147L97.0654 103.577L98.7211 105.646L97.1609 106.888L92.2256 100.711C92.8306 100.074 93.4037 99.5489 93.8336 99.1987H93.8495ZM96.6037 102.001C97.145 101.571 97.1927 100.934 96.747 100.361C96.3331 99.8355 95.7758 99.7081 95.0435 100.281C94.948 100.361 94.8366 100.472 94.6933 100.584L96.1261 102.367L96.6037 101.985V102.001Z"
            fill="#015958"
          />
          <path
            id="Vector_19"
            className="logo_foreground_color"
            d="M104.564 95.5848L98.8803 94.9639L99.8196 93.2764L103.609 93.8017L101.65 90.5063L102.829 88.9939L105.662 93.9132L108.003 95.4574L106.872 97.145L104.532 95.6007L104.564 95.5848Z"
            fill="#015958"
          />
        </g>
        <path
          id="smile"
          className="logo_foreground_color"
          d="M121.073 77.7064C120.611 96.2216 109.674 113.208 91.9868 119.895C68.3295 128.842 41.5358 115.994 32.1588 91.1908C30.4712 86.7332 29.4683 82.1959 29.0862 77.7064H24.6285C26.0454 104.309 48.0471 125.435 75 125.435C101.953 125.435 123.955 104.309 125.371 77.7064H121.073Z"
          fill="#015958"
        />
        <path
          id="asterisc2"
          className="logo_foreground_color"
          d="M45.5476 77.786L42.2203 77.0219L45.9138 79.6965C45.8661 79.983 45.7546 80.19 45.5954 80.3492C45.4362 80.5562 45.1815 80.6676 44.9108 80.795C44.1785 80.19 42.4273 78.2318 41.7905 77.2607C42.3159 79.7283 42.5547 80.5402 42.5547 81.2726C42.3955 81.5114 42.1885 81.7661 41.9497 81.9253C41.7109 82.1322 41.4562 82.2437 41.1855 82.3711C40.899 82.2119 40.692 81.9731 40.5806 81.6865C40.4532 81.3681 40.3736 81.0019 40.3736 80.6358L41.1378 77.4676L38.6224 81.0019C38.304 80.9223 38.0492 80.795 37.8582 80.6039C37.699 80.4447 37.5716 80.2377 37.5398 79.9989C37.9378 79.3144 39.7686 77.7224 40.7875 77.0378C37.5398 77.6109 37.2214 77.802 36.8871 77.6428C36.6483 77.4836 36.4413 77.2766 36.2821 77.0696C36.0751 76.8308 35.9159 76.5443 35.7886 76.2577C36.3617 75.6527 36.9189 75.4458 37.5398 75.4458C37.9378 75.4458 38.0652 75.6527 40.7079 76.3373L37.5398 73.3284C37.5876 73.1214 37.699 72.8826 37.906 72.6757C38.1448 72.4369 38.4313 72.3573 38.6383 72.3573L41.2333 76.0985C40.7875 73.7901 40.501 72.7234 40.501 72.2458C40.501 72.1184 40.5009 72.0388 40.5487 71.9592C40.8671 71.4657 41.3606 71.0996 41.9338 70.8607C42.5387 71.4339 42.7457 71.9911 42.7457 72.612C42.6661 73.5513 42.1726 74.9682 41.8542 76.1463C42.5387 75.1751 44.2422 73.2647 44.9427 72.5801C45.1815 72.6597 45.4362 72.7871 45.6272 72.9781C45.7865 73.1373 45.9138 73.3761 45.9457 73.6627L42.2044 76.4169L45.9775 75.5572C46.3914 75.6368 46.6621 75.8756 46.9168 76.1622C47.076 76.401 47.2352 76.6875 47.3148 76.9741C46.7417 77.5791 46.1845 77.786 45.5636 77.786H45.5476Z"
          fill="#015958"
        />
        <path
          id="asterisc1"
          className="logo_foreground_color"
          d="M113.065 77.786L109.738 77.0219L113.431 79.6965C113.384 79.983 113.272 80.19 113.113 80.3492C112.954 80.5562 112.699 80.6676 112.428 80.795C111.696 80.19 109.945 78.2318 109.308 77.2607C109.833 79.7283 110.072 80.5402 110.072 81.2726C109.913 81.5114 109.706 81.7661 109.467 81.9253C109.228 82.1322 108.974 82.2437 108.703 82.3711C108.416 82.2119 108.21 81.9731 108.098 81.6865C107.971 81.3681 107.891 81.0019 107.891 80.6358L108.655 77.4676L106.14 81.0019C105.821 80.9223 105.567 80.795 105.376 80.6039C105.217 80.4447 105.089 80.2377 105.057 79.9989C105.455 79.3144 107.286 77.7224 108.305 77.0378C105.057 77.6109 104.739 77.802 104.405 77.6428C104.166 77.4836 103.959 77.2766 103.8 77.0696C103.593 76.8308 103.433 76.5443 103.306 76.2577C103.879 75.6527 104.436 75.4458 105.057 75.4458C105.455 75.4458 105.583 75.6527 108.225 76.3373L105.057 73.3284C105.105 73.1214 105.217 72.8826 105.423 72.6757C105.662 72.4369 105.949 72.3573 106.156 72.3573L108.751 76.0985C108.305 73.7901 108.018 72.7234 108.018 72.2458C108.018 72.1184 108.018 72.0388 108.066 71.9592C108.385 71.4657 108.878 71.0996 109.451 70.8607C110.056 71.4339 110.263 71.9911 110.263 72.612C110.184 73.5513 109.69 74.9682 109.372 76.1463C110.056 75.1751 111.76 73.2647 112.46 72.5801C112.699 72.6597 112.954 72.7871 113.145 72.9781C113.304 73.1373 113.431 73.3761 113.463 73.6627L109.722 76.4169L113.495 75.5572C113.909 75.6368 114.18 75.8756 114.434 76.1622C114.594 76.401 114.753 76.6875 114.832 76.9741C114.259 77.5791 113.702 77.786 113.081 77.786H113.065Z"
          fill="#015958"
        />
      </g>
    </g>
  </svg>
);
export default LogoSvg;
