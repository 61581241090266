import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./ColabForm.css";
import "../main_components/Forms.css";

function ColabForm() {
  const [status, setStatus] = useState("idle");

  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    sobre: "",
    arquivo: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ligatipo-form",
        "template_cyeycir",
        e.target,
        "otInTKq0q4NZ5pmo4"
      )
      .then(
        (result) => {
          setStatus("success");
          console.log(result.text);
          // Aqui você pode colocar alguma notificação de sucesso
        },
        (error) => {
          setStatus("error");
          console.log(error.text);
          // Aqui você pode colocar alguma notificação de erro
        }
      );
  };

  return (
    <div className="colab-container">
      {status === "idle" && (
        <>
          <p className="colab-text">
            Estamos super animados para conhecer o seu projeto de fonte! Para
            começar essa jornada colaborativa, basta preencher o formulário
            abaixo e nos enviar um link contendo um PDF do seu projeto.
            Ficaremos felizes em entrar em contato em breve para uma conversa
            inspiradora. Mesmo que, por algum motivo, não consigamos produzir
            juntos, você pode contar com nosso compromisso de fornecer um
            feedback detalhado e construtivo. Queremos apoiá-lo a crescer e se
            desenvolver no maravilhoso mundo do design de tipos!
          </p>
          <form className="colab-form" onSubmit={handleSubmit}>
            <div className="label-float">
              <input
                type="text"
                name="nome"
                onChange={handleChange}
                value={formData.nome}
                placeholder=""
                required
              />
              <label htmlFor="nome">nome</label>
            </div>

            <div className="label-float">
              <input
                type="email"
                name="email"
                onChange={handleChange}
                value={formData.email}
                placeholder=""
                required
              />
              <label htmlFor="email">email</label>
            </div>

            <div className="label-float">
              <input type="text" name="link" placeholder=" " />
              <label htmlFor="link">link para o PDF</label>
            </div>

            <div className="label-float area">
              <textarea
                name="sobre"
                onChange={handleChange}
                value={formData.sobre}
                placeholder=""
                required
              />
              <label htmlFor="sobre">conte um pouco sobre o seu projeto</label>
            </div>

            <button className="btn" type="submit">
              enviar
            </button>
          </form>
        </>
      )}
      {status === "success" && (
        <div className="success-message">
          <h2>Email enviado com sucesso!</h2>
          <p>Entraremos em contato em breve.</p>
        </div>
      )}
      {status === "error" && (
        <div className="error-message">
          <h2>Ops, algo deu errado!</h2>
          <button className="btn" onClick={() => setStatus("idle")}>
            Tentar Novamente
          </button>
        </div>
      )}
    </div>
  );
}

export default ColabForm;
