import ColabForm from "../ui_components/ColabForm";
import LogoSvg from "../ui_components/LogoSvg";
import Giveback from "../main_components/ManifestGiveback";
import "./Colab.css";

function Colab() {
  return (
    <>
      <div className="horizontal-line fixed-item" id="line1"></div>
      <div className="horizontal-line fixed-item" id="line2"></div>
      <LogoSvg />
      <Giveback />
      <div className="colab">
        <h1 className="section__title">Produza com a gente</h1>
        <ColabForm />
      </div>
    </>
  );
}

export default Colab;
