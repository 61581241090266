import React, { useState } from "react";
import "./Header.css";
import Menu from "./Menu";
import SvgInstagram from "../ui_components/SvgInstagram";
import { Squash as Hamburger } from "hamburger-react";

// import SideCart from "../ui_components/SideCart";
// import BagBtn from "../ui_components/BagBtn";

function Header() {
  // const [isCartOpen, setIsCartOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="header">
      <Menu isOpen={isMenuOpen} />
      <Hamburger
        toggled={isMenuOpen}
        toggle={setIsMenuOpen}
        size={22}
        color="#015958"
        rounded
      />
      <a className="logo-mobile mobile-item" href="/">
        <img
          src="/assets/img/logo-mobile.svg"
          alt="Ligatipo"
          className="logo"
        />
      </a>
      <a
        href="https://www.instagram.com/ligatipo/"
        target="_blank"
        rel="noreferrer"
      >
        <SvgInstagram className="instagram-icon" />
      </a>

      {/* <div
        className={`icon-container ${isCartOpen ? "open" : ""}`}
        onClick={() => setIsCartOpen(!isCartOpen)}
      >
        <BagBtn isOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />
      </div>
      <SideCart isOpen={isCartOpen} setIsCartOpen={setIsCartOpen} /> */}
    </header>
  );
}

export default Header;
