import * as React from "react";

const SvgInstagram = ({ className }) => (
  <svg
    // width="25px"
    // height="25px"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7 4.67C5.71 4.67 4.67 5.72 4.67 7C4.67 8.28 5.72 9.33 7 9.33C8.28 9.33 9.33 8.28 9.33 7C9.33 5.72 8.28 4.67 7 4.67ZM14 7C14 6.03 14 5.08 13.95 4.11C13.9 2.99 13.64 1.99 12.82 1.18C12 0.36 11.01 0.1 9.89 0.05C8.92 -4.84288e-08 7.97 0 7 0C6.03 0 5.08 -4.84288e-08 4.11 0.05C2.99 0.1 1.99 0.36 1.18 1.18C0.36 2 0.1 2.99 0.05 4.11C-4.84288e-08 5.08 0 6.03 0 7C0 7.97 -4.84288e-08 8.92 0.05 9.89C0.1 11.01 0.36 12.01 1.18 12.82C2 13.64 2.99 13.9 4.11 13.95C5.08 14 6.03 14 7 14C7.97 14 8.92 14 9.89 13.95C11.01 13.9 12.01 13.64 12.82 12.82C13.64 12 13.9 11.01 13.95 9.89C14.01 8.93 14 7.97 14 7ZM7 10.59C5.01 10.59 3.41 8.99 3.41 7C3.41 5.01 5.01 3.41 7 3.41C8.99 3.41 10.59 5.01 10.59 7C10.59 8.99 8.99 10.59 7 10.59ZM10.74 4.1C10.28 4.1 9.9 3.73 9.9 3.26C9.9 2.79 10.27 2.42 10.74 2.42C11.21 2.42 11.58 2.79 11.58 3.26C11.5825 3.36954 11.5625 3.47842 11.5212 3.57992C11.48 3.68141 11.4183 3.77333 11.34 3.85C11.2633 3.92827 11.1714 3.98997 11.0699 4.03125C10.9684 4.07253 10.8595 4.09253 10.75 4.09L10.74 4.1Z"
      fill="#015958"
    />
  </svg>
);

export default SvgInstagram;
