import "./ManifestTool.css";
import SvgRacket from "../ui_components/SvgRacket";

function ManifestTool() {
  return (
    <section className="manifest-tool manifest-section">
      <div className="manifest-container-tool">
        <SvgRacket className="manifest-racket" />
      </div>
    </section>
  );
}

export default ManifestTool;
